var wdot = wdot || {};
wdot.PhotoCredit = function () {
    var photoCreditClick = function (element) {
        var text = $(element).parent().find(".photo-credit__text");
        var icon = $(element);
        text.css('left', 0);
        if (icon.hasClass("active")) {
            text.animate({ left: "+=100", opacity: "0", easing: "easeOutCubic" }, 500, function () {
                text.css('left', 0);
                text.addClass('hidden');
            });
        } else {
            text.removeClass('hidden');
            text.fadeIn();
            text.css('left', 100);
            text.animate({ left: "-=100", opacity: "1", easing: "easeInCubic" }, 500, function () {
                text.css('left', 0);
            });
        }
        icon.toggleClass("active");
    },
    loadPermissionMembersAutocomplete = function (textboxSelector, selectorFind) {
               
    };
    return {
        photoCreditClick: photoCreditClick,
        loadPermissionMembersAutocomplete: loadPermissionMembersAutocomplete
    };
}();